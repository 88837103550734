import { useTranslate } from '/machinery/I18n'

import { TagButton } from '/features/buildingBlocks/TagButton'

import styles from './Tags.css'

export function Tags({ tags, onActiveTagChange, contentIsCentered }) {
  return <TagsBase {...{ tags, onActiveTagChange, contentIsCentered }} />
}

export function TagsCanonical({ tags, onActiveTagChange, contentIsCentered }) {
  return <TagsBase {...{ tags, onActiveTagChange, contentIsCentered }} />
}

function TagsBase({ tags, onActiveTagChange, contentIsCentered = false }) {
  const { __ } = useTranslate()

  return (
    <div className={cx(styles.componentBase, contentIsCentered && styles.isCentered)}>
      <p className={styles.tagsTitle}>{__`read-more-about-subjects`}</p>
      <ul className={styles.tagsList}>
        {tags.map((tag, index) => <Tag key={index} {...{ tag, index, onActiveTagChange }} />)}
      </ul>
    </div>
  )
}

function Tag({ tag, onActiveTagChange, index }) {
  return (
    <li>
      <TagButton
        label={tag}
        buttonId={index}
        dataX='click-to-open-tag-related-articles'
        onClick={handleClick}
      />
    </li>
  )

  function handleClick() {
    onActiveTagChange(tag)
  }
}
