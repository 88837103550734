import { PhotoIconOverlay } from '/features/buildingBlocks/PhotoIconOverlay'
import { ShareButton } from '/features/buildingBlocks/ShareButton'
import { useMediaQuery } from '/machinery/useCachingMediaQuery'
import { useTranslate } from '/machinery/I18n'

import mediaStyles from '/cssGlobal/media.css'
import styles from './ArticleButtons.css'

export function ArticleButtons({ hero = undefined, region = undefined, layoutClassName = undefined }) {
  const isViewportMd = useMediaQuery(mediaStyles.viewportMd)
  const { __ } = useTranslate()

  const caption = getCaption()
  const image = getImage()

  return (
    <div className={cx(styles.component, layoutClassName)}>
      {caption && (
        <PhotoIconOverlay
          layoutClassName={styles.creditsButtonLayout}
          renderContent={() => <p className={styles.captionText}>{caption}</p>}
        />
      )}

      {image && (
        <ShareButton
          type='region'
          title={['Rabo &Co', region].filter(Boolean).join(' - ')}
          shareText={__`share-share-text`}
          text={__`share-text`}
          layoutClassName={styles.shareButtonLayout}
          {...{ image }}
        />
      )}
    </div>
  )

  function getCaption() {
    return isViewportMd
      ? hero?.imageCaption
      : hero?.imageSecondary
        ? hero?.imageCaptionSecondary
        : hero?.imageCaption
  }

  function getImage() {
    return isViewportMd
      ? hero?.image
      : hero?.imageSecondary
        ? hero?.imageSecondary
        : hero?.image
  }
}
