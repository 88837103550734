import { useTranslate } from '/machinery/I18n'

import { CardUitgelezenContent } from '/features/regionArticles/cards/CardUitgelezenContent'

import styles from './NextArticleUitgelezen.css'

export function NextArticleUitgelezen({ doc, issues, layoutClassName = undefined }) {
  const { __ } = useTranslate()

  return (
    <section className={cx(styles.component, layoutClassName)} data-x='next-article-uitgelezen'>
      <CardUitgelezenContent
        title={__`you-have-finished-reading-rabo-and-co`}
        layoutClassName={styles.contentLayout}
        {...{ issues }}
      />
    </section>
  )
}
