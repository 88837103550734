import { useMediaQuery } from '/machinery/useCachingMediaQuery'

import { ContainerLg } from '/features/buildingBlocks/Container'
import { ImageCover } from '/features/buildingBlocks/Image'
import { CardImage } from '/features/regionArticles/cards/CardImage'
import { HeadingGroupSnackables } from '/features/buildingBlocks/HeadingGroup'
import { CardSnackablesContentIntro } from '/features/regionArticles/cards/CardSnackables'

import gradientOverlayStyles from '/features/buildingBlocks/gradientOverlay.css'
import mediaStyles from '/cssGlobal/media.css'
import styles from './ArticleHero.css'

export function ArticleHeroDefault({ hero, layoutClassName = undefined }) {
  const isViewportMd = useMediaQuery(mediaStyles.viewportMd)
  const image = getImage()

  return (
    <ArticleHeroBase className={cx(styles.componentDefault, gradientOverlayStyles.gradientOverlayBlack, layoutClassName)}>
      {image && (
        <>
          <ArticleHeroImage
            aspectRatio='9 / 16'
            inert={Boolean(!isViewportMd).toString()}
            layoutClassName={styles.imageLayout}
            {...{ image }}
          />

          <ArticleHeroImage
            aspectRatio='16 / 9'
            inert={Boolean(isViewportMd).toString()}
            layoutClassName={styles.imageLayout}
            {...{ image }}
          />
        </>
      )}
    </ArticleHeroBase>
  )

  function getImage() {
    return isViewportMd
      ? hero.image
      : hero.imageSecondary
        ? hero.imageSecondary
        : hero.image
  }
}

function ArticleHeroImage({ image, inert, aspectRatio, layoutClassName }) {
  return (
    <div
      className={cx(styles.componentImage, layoutClassName)}
      data-aspect-ratio={aspectRatio}
      {...{ inert }}
    >
      <ImageCover
        sizes='100vw'
        imgProps={{ fetchpriority: 'high' }}
        layoutClassName={styles.imageCoverLayout}
        {...{ image, aspectRatio }}
      />
    </div>
  )
}

export function ArticleHeroSingleSnackable({ heroImage, title, rubric, layoutClassName = undefined }) {
  return (
    <ArticleHeroBase className={cx(styles.componentSingleSnackable, layoutClassName)}>
      <ContainerLg layoutClassName={styles.containerLayout}>
        <div className={styles.singleSnackableContentWrapper}>
          <div className={styles.singleSnackableContentContainer}>
            {heroImage && <CardImage image={heroImage} aspectRatio={4 / 5} layoutClassName={styles.imageLayout} />}
            <div className={styles.headingContainer}>
              <HeadingGroupSnackables subtitle={rubric} {...{ title }} />
            </div>
          </div>
        </div>
      </ContainerLg>
    </ArticleHeroBase>
  )
}

export function ArticleHeroSnackables({ title, rubric, articles, layoutClassName = undefined }) {
  return (
    <ArticleHeroBase className={cx(styles.componentSnackables, layoutClassName)}>
      <CardSnackablesContentIntro layoutClassName={styles.snackablesCardLayout} {...{ title, rubric, articles }} />
    </ArticleHeroBase>
  )
}

function ArticleHeroBase({ children, className }) {
  return (
    <div className={cx(styles.componentBase, className)}>
      {children}
    </div>
  )
}
