import styles from './ContentContainer.css'

export function ContentContainerDefault({ children }) {
  return (
    <ContentContainerBase className={styles.componentDefault} {...{ children }} />
  )
}

export function ContentContainerCentered({ children }) {
  return (
    <ContentContainerBase className={styles.componentCentered} {...{ children }} />
  )
}

function ContentContainerBase({ children, className }) {
  return <div className={cx(styles.componentBase, className)}>{children}</div>
}
