import { useSpring } from '@react-spring/web'
import { useScrollProgression, triggers }  from '@kaliber/scroll-progression'
import { lerp } from '@kaliber/math'

export function useScrollProgressionProgressbar({ scrollParentEnd, onEndReached }) {
  const [hideBar, setHideBar] = React.useState(true)
  const [reachedEnd, setReachedEnd] = React.useState(false)
  const [{ width }, api] = useSpring(() => ({ width: '0%' }))
  const amplitude = 4

  const trackedRefProgressbar = useScrollProgression({
    start: { element: triggers.top(), scrollParent: triggers.bottom() },
    end: { element: triggers.bottom(), scrollParent: triggers.custom(scrollParentEnd) },
    onChange(progression) {
      api.start({ width: progression * (100 + amplitude) + '%' })
      setHideBar((progression < 0.025) || (progression > 0.99))

      if (reachedEnd === false && progression >= 0.94) {
        setReachedEnd(true)
        onEndReached()
      }
    }
  })

  return { trackedRefProgressbar, width, hideBar, reachedEnd }
}

export function useScrollProgressionScalingCard() {
  const [{ scale }, api] = useSpring(() => ({
    scale: 0.85,
    config: { tension: 500, friction: 35 }
  }))

  const { y } = useSpring({
    from: { y: 50 },
    to: { y: 0 },
    config: { tension: 500, friction: 35 }
  })

  const scrollProgressionScalingRef = useScrollProgression({
    start: { element: triggers.top(), scrollParent: triggers.bottom() },
    end: { element: triggers.top(), scrollParent: triggers.custom(0.5) },
    onChange(progression) {
      api.start({ scale: lerp({ start: 0.9, end: 1, input: easeOut(progression) }) })
    }
  })

  return {
    scrollProgressionScalingRef,
    scale,
    y
  }
}

function easeOut(x) {
  return Math.sin((x * Math.PI) / 2)
}
