import { ContainerMd } from '/features/buildingBlocks/Container'
import { WoodwingContent } from '/features/Woodwing'
import { HeadingGroup } from '/features/buildingBlocks/HeadingGroup'

import styles from './PopupContentDefault.css'

// TODO: Erik / Erwin RABOCO-393 - deze classes zijn verwarrend, zijn niet echt gerelateerd en dingen lopen door elkaar heen, als we rust hebben moeten we ze een keer recht trekken

// TODO: Erik RABOCO-393 - do we need active or is this just a mount of the popup?
export function PopupContentDefault({ renderCard, layoutClassName = undefined }) {
  return (
    <div className={cx(styles.component, layoutClassName)}>
      {renderCard({ layoutClassName: styles.cardLayout })}
    </div>
  )
}

export function PopupContentDefaultCardGreen({ title, subtitle, contentItems, imageElement, layoutClassName }) {
  return (
    <PopupContentCard
      {...{ layoutClassName }}
      colorClassName={styles.green}
      renderImage={() => imageElement}
      renderContent={({ layoutClassName }) =>
        <Content
          subtitleClassName={styles.headingMd}
          {...{ title, subtitle, contentItems, layoutClassName }}
        />
      }
    />
  )
}

export function PopupContentDefaultCardContentOnly({ title, subtitle, contentItems, layoutClassName }) {
  return (
    <PopupContentCardContentOnly
      {...{ layoutClassName }}
      renderContent={() =>
        <ContainerMd>
          <Content
            styleId='page'
            subtitleClassName={styles.headingMd}
            {...{ title, subtitle, contentItems }}
          />
        </ContainerMd>
      }
    />
  )
}

export function PopupContentDefaultCardWhite({ title, subtitle, contentItems, imageElement, layoutClassName }) {
  return (
    <PopupContentCard
      {...{ layoutClassName }}
      colorClassName={styles.white}
      renderImage={() => imageElement}
      renderContent={({ layoutClassName }) =>
        <Content subtitleClassName={styles.versionLg} {...{ title, subtitle, contentItems, layoutClassName }} />
      }
    />
  )
}

function PopupContentCard({ colorClassName, renderContent, renderImage = undefined, layoutClassName = undefined }) {
  return (
    <div className={cx(styles.componentPopupContentCard, layoutClassName, colorClassName)}>
      {renderImage && renderImage()}
      {renderContent({ layoutClassName: styles.contentLayout })}
    </div>
  )
}

function PopupContentCardContentOnly({ renderContent, layoutClassName = undefined }) {
  return (
    <div className={cx(styles.componentPopupContentCardContentOnly, layoutClassName)}>
      {renderContent()}
    </div>
  )
}

/**
 * @param {{
*   contentItems: any,
*   title: string,
*   subtitle: string,
*   subtitleClassName: string,
*   styleId?: 'default' | 'snackable' | 'page' | 'conclusion' | 'none',
*   layoutClassName?: string,
* }} props
*/
function Content({ title, subtitle, contentItems, subtitleClassName, styleId = 'default', layoutClassName = undefined }) {
  return (
    <div className={cx(styles.componentContent, layoutClassName)}>
      <HeadingGroup layoutClassName={styles.headingGroupLayout} {...{ title, subtitle, subtitleClassName }} />
      <WoodwingContent {...{ contentItems, styleId }} />
    </div>
  )
}
