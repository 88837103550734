import { ImageCover } from '/features/buildingBlocks/Image'

import styles from './RelatedArticle.css'

export function RelatedArticle({ url, item }) {
  const { content, metadata } = item
  const { hero } = content
  const { name: rubric } = metadata.rubric

  return (
    <li className={styles.component}>
      {hero.image && <RelatedArticleImage image={hero.image} layoutClassName={styles.deckItemImageLayout} />}
      <RelatedArticleContent title={hero.title} layoutClassName={styles.deckItemContentLayout} {...{ url, rubric }} />
    </li>
  )
}

function RelatedArticleContent({ rubric, url, title, onClick = undefined, layoutClassName = undefined }) {
  return (
    <div className={cx(styles.componentContent, layoutClassName)}>
      <span className={styles.rubricText}>{rubric}</span>
      <a href={url} data-x='link-to-rubriek' onClick={handleClick} className={styles.deckItemContentAnchor}>
        {title}
      </a>
    </div>
  )

  function handleClick(e) {
    if (!onClick || !shouldNavigate(e)) return
    e.preventDefault()
    onClick()
  }
}

function RelatedArticleImage({ image, layoutClassName = undefined }) {
  return (
    <div className={cx(styles.componentImage, layoutClassName)}>
      <ImageCover aspectRatio={1 / 1} layoutClassName={styles.deckItemImageLayout} {...{ image }} />
    </div>
  )
}

function shouldNavigate(e) {
  return (
    !e.defaultPrevented &&
    e.button === 0 &&
    !(e.metaKey || e.altKey || e.ctrlKey || e.shiftKey)
  )
}
