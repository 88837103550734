import styles from './Credits.css'

export function Credits({ credits, centered = false }) {
  return (
    <ul className={cx(styles.component, centered && styles.isCentered)}>
      {credits.map((item, i) => <Tag key={i} label={item} />)}
    </ul>
  )
}

function Tag({ label }) {
  return <li className={styles.componentTag}>{label}</li>
}
